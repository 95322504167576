import React, {Dispatch, SetStateAction} from "react";
import styled from "styled-components";
import {Dislike, KebabMenu, Like} from "../../../common";
import dayjs from 'dayjs';
import {getReply, postCommentLike} from "../../../common/apis/comment";
import {useRecoilState} from "recoil";
import {optionState} from "../../stores/room";
import Reply from "./Reply";
import {ReplyData} from "../../containers/LaunchTask/LaunchTask";
import {refreshReplyState} from "../../stores/reply";
import {userState} from "../../stores/user";


export declare type Comment = {
    created_at: string
    created_by: string
    description: string
    location: string
    room: string
    updated_at: string
    updated_by: string
    uuid: string
    nickname?: string | null | undefined
    like: number;
    dislike: number;
    comment_like: 1 | -1 | 0;
    reply_count: number;
}


export const Comment = React.forwardRef<
    HTMLDivElement,
    { data: Comment, setInput: Dispatch<SetStateAction<string>>, setReplyData: Dispatch<SetStateAction<ReplyData | null>>, commentRef: any }
>(({
       data,
       setInput,
       setReplyData,
       commentRef
   }, ref) => {
    const [currentLike, setCurrentLike] = React.useState<-1 | 0 | 1>(data.comment_like);
    const [currentReplyList, setCurrentReplyList] = React.useState<Comment[]>([]);
    const [replyCount, setReplyCount] = React.useState(data.reply_count);
    const [isReplyOpen, setIsReplyOpen] = React.useState(false);
    const [refreshReply, setRefreshReply] = useRecoilState(refreshReplyState);
    const [user, setUser] = useRecoilState(userState);

    const [option, setOption] = useRecoilState(optionState);

    const date = React.useMemo(() => {
        const UTCDate = new Date(data.created_at);

        return dayjs(UTCDate).format('YYYY.MM.DD HH:mm:ss')
    }, []);

    const userName = React.useMemo(() => {
        return data.nickname?.slice(0, 11)
    }, []);

    React.useEffect(() => {
        if (refreshReply === data.uuid) {
            handleRefreshReply()
            // Refresh 후 초기화
            setRefreshReply("")
        }
    }, [refreshReply])

    const handleRefreshReply = async () => {
        // 열려있으면 닫기

        const res = await getReply(data.uuid)

        if (res.status === 200) {
            setCurrentReplyList(res.data);
            setReplyCount(res.data.length);
        }
    }

    const handleReply = async () => {
        // 열려있으면 닫기
        if (isReplyOpen) {
            setReplyData(null)
            setInput("")
            setCurrentReplyList([]);
            setIsReplyOpen(false)

        } else {// 닫혀있으면 열기

            setIsReplyOpen(true)

            // 답글달기 input
            setReplyData({
                commentId: data.uuid,
                mentionId: data.created_by,
            });

            // mention name 은 백엔드로는 보내지 않음
            const mentionName = data.nickname?.slice(0, 11) || userName?.slice(0, 11)
            setInput(`@${mentionName} : `);
            commentRef.current.focus();

            // 답글 불러오기
            if (data.reply_count !== 0) {
                const res = await getReply(data.uuid)

                if (res.status === 200) {
                    setCurrentReplyList(res.data);
                }
            }
        }
    }

    const handleLike = async () => {
        // 1 -> 0
        // 0 -> 1
        // -1 -> 1

        const like = currentLike === 1 ? 0 : 1;
        const res = await postCommentLike({
            comment_uuid: data.uuid,
            like: like
        })

        if (res.status === 201) {
            setCurrentLike(like);
        }
    }

    const handleDislike = async () => {
        // nothing
        // -1 -> 0
        // 0 -> -1
        // 1 -> -1

        const dislike = currentLike === -1 ? 0 : -1;
        const res = await postCommentLike({
            comment_uuid: data.uuid,
            like: dislike
        })

        if (res.status === 201) {
            setCurrentLike(dislike);
        }

    }

    const handleOpenOption = () => {
        setOption({
            open: true,
            type: "default",
            id: data.uuid,
            userId: data.created_by,
            isReply: false,
            firstCommentId: data.uuid,
        })
    }

    return (
        <Wrapper ref={ref}>
            <Header>
                {/* header */}
                <Info>
                    <Name isOwn={user?.uuid === data.created_by}>
                        {data.nickname?.slice(0, 11) || userName?.slice(0, 11)}
                    </Name>
                    <DateStyle>
                        {date}
                    </DateStyle>
                </Info>
                <MenuWrapper>
                    <KebabMenu onClick={handleOpenOption} width={10} height={10}/>
                </MenuWrapper>
            </Header>
            <Body>
                {/* body */}
                {data.description}
            </Body>
            <Footer>
                <MainFooter>
                    {/* footer */}
                    <ReplyStyle>
                        {/* reply */}
                        <ReplyWrapper onClick={handleReply}>
                            답글 {replyCount == 0 ? "" : replyCount}
                        </ReplyWrapper>

                    </ReplyStyle>
                    <Response>
                        {/* response */}
                        <LikeWrapper onClick={handleLike} active={currentLike === 1}>
                            {/* like */}
                            <LikeIcon width={12} height={12} active={currentLike === 1}/>
                            {data.like + (data.comment_like === 1 && currentLike !== 1 ? -1 : 0) + (data.comment_like !== 1 && currentLike === 1 ? 1 : 0)}
                        </LikeWrapper>
                        <DislikeWrapper onClick={handleDislike} active={currentLike === -1}>
                            {/* dislike */}
                            <DislikeIcon width={12} height={12} active={currentLike === -1}/>
                            {data.dislike + (data.comment_like === -1 && currentLike !== -1 ? -1 : 0) + (data.comment_like !== -1 && currentLike === -1 ? 1 : 0)}
                        </DislikeWrapper>
                    </Response>
                </MainFooter>
                <ReplyList>
                    {/* comments */}
                    {currentReplyList?.map(v => {
                        return <Reply key={v.uuid} data={v} setInput={setInput} setReplyData={setReplyData}
                                      firstCommentId={data.uuid} commentRef={commentRef}/>
                    })}
                </ReplyList>
            </Footer>
        </Wrapper>
    )
});

export default Comment;

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  width: 100%;
  word-break: break-all;
  font-size: 14px;
  color: black;
  white-space: pre-line;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MenuWrapper = styled.div`
  cursor: pointer;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Name = styled.p<{ isOwn: boolean }>`
  font-size: 14px;
  font-weight: 700;

  color: ${props => props.isOwn ? '#399' : 'black'};
`;

const DateStyle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #767171;
`;

const MainFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ReplyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ReplyStyle = styled.div`
  font-size: 14px;
  color: #737373;
  cursor: pointer;
`;

const Response = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const ReplyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
`;

const LikeWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2px;
  cursor: pointer;
  color: ${props => props.active ? 'rgb(0,0,75)' : 'gray'};
  font-weight: ${props => props.active ? '700' : '400'};
  font-size: 14px;
  align-items: center;
`;

const DislikeWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2px;
  cursor: pointer;
  color: ${props => props.active ? 'rgb(0,0,75)' : 'gray'};
  font-weight: ${props => props.active ? '700' : '400'};
  font-size: 14px;
  align-items: center;
`;


const LikeIcon = styled(Like)<{ active: boolean }>`
  > path {
    stroke: ${props => props.active ? 'rgb(0,0,75)' : 'gray'};
    stroke-width: ${props => props.active ? 3 : 1.5};
  }
`

const DislikeIcon = styled(Dislike)<{ active: boolean }>`
  > path {
    stroke: ${props => props.active ? 'rgb(0,0,75)' : 'gray'};
    stroke-width: ${props => props.active ? 3 : 1.5};
  }
`
